import React from "react"

import Hero from "../layouts/Hero"
import ParagraphImage from "../layouts/ParagraphImage"
import Paragraph from "../layouts/Paragraph"

const AllLayouts = ({ layoutData }) => {
  
  const layoutType = layoutData.fieldGroupName

  /**
   * Default component
   */
  const Default = () => <div>In AllLayouts the mapping of this component is missing: {layoutType}</div>

  /**
   * Mapping the fieldGroupName(s) to our components
   */
  const layouts = {
    page_AcfPagebuilder_Layouts_Hero          : Hero,
    page_AcfPagebuilder_Layouts_ParagraphImage: ParagraphImage,
    page_AcfPagebuilder_Layouts_Paragraph     : Paragraph,
    page_default: Default
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = layouts[layoutType] ? layouts[layoutType] : layouts['page_default']

  return (
    <ComponentTag {...layoutData} />
  )
}

export default AllLayouts