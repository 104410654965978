import React from "react"

const Paragraph = (props ) => {

  const paragraph = props.paragraph
  
  return (
    <div className="paragraph">
    
     <div className="paragraph__content" dangerouslySetInnerHTML={{ __html: paragraph }}/>
  </div>
  )
}

export default Paragraph