
import React from "react"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import AllLayouts from "../../components/AllLayouts"

const Page = ({ pageContext }) => {
  const {
    page: { title, featuredImage, excerpt, acf_pageBuilder },
  } = pageContext

  
  const layouts = acf_pageBuilder.layouts || []
  return (
    <Layout>
       <Seo title={title}
           image={featuredImage}
           description={excerpt}
      />


      <h1> {title} </h1>
      
      {
        layouts.map((layout, index) => {
          return <AllLayouts key={index} layoutData={layout} />
        })
      }

    </Layout>
  )
}

export default Page