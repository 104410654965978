import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ParagraphImage = (props ) => {

  const image = getImage( props.image.imageFile.childImageSharp )
  const paragraph = props.paragraph
  
  return (
    <div className="hero">
    <div>
      <GatsbyImage image={ image} alt="avatar" />
    </div>
    
     <div className="hero__detail" dangerouslySetInnerHTML={{ __html: paragraph }}/>
  </div>
  )
}

export default ParagraphImage